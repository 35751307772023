window.initZendesk = function () {
  (function (a, b, c, d) {
    a = 'https://static.zdassets.com/ekr/snippet.js?key=9c436d89-e093-4eed-aff1-f5b1a28ed0b7';
    b = document;
    c = 'script';
    d = b.createElement(c);
    d.src = a;
    d.id = 'ze-snippet';
    d.type = 'text/java' + c;
    d.async = true;
    d.defer = true;
    a = b.getElementsByTagName(c)[0];
    a.parentNode.insertBefore(d, a);

    // decrease the size of the zendesk widget to 48px
    function injectStyles() {
      const iframe = document.getElementById('launcher');
      if (!iframe) {
        requestAnimationFrame(injectStyles);
        return;
      }
      iframe.style.height = '48px';
      iframe.style.width = '48px';

      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      if (!iframeDoc.body) {
        requestAnimationFrame(injectStyles);
        return;
      }

      const style = iframeDoc.createElement('style');
      style.textContent = `        
          div[shape="circle"] {
            width: 48px !important;
            height: 48px !important;
          }
        `;

      iframeDoc.head.appendChild(style);
    }

    // because of cookie consent the zendesk widget can appear and disappear again and again
    // so we need to observe the body for changes and reapply the decreased size of 48px
    const isLauncherNode = (node) => node.id === 'launcher';

    const handleMutation = (mutation) => {
      if (mutation.type === 'childList') {
        const addedLauncher = Array.from(mutation.addedNodes).find(isLauncherNode);
        if (addedLauncher) {
          injectStyles();
        }
      }
    };

    const setupZendeskObserver = () => {
      const targetNode = document.body;
      const config = { childList: true, subtree: true };
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach(handleMutation);
      });
      observer.observe(targetNode, config);
    };

    // Call setupZendeskObserver immediately after the script is loaded
    d.onload = setupZendeskObserver;
  })();
};
